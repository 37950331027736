function formSource(){
    const sourceFields = document.querySelectorAll('.umbraco-forms-field.hidden.source input[type=hidden]');
    const pageTitle = document.querySelector('h1').innerText;

    function escape(string) {
        return string
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");
    }

    sourceFields.forEach(function(sourceField){
        const value = escape(pageTitle);
        sourceField.value = value;
    })
}

formSource();