const videoContainer = document.querySelectorAll('.video-container');

if (typeof(videoContainer) != 'undefined' && videoContainer != null) {
    for (let i = 0; i < videoContainer.length; i++) {
        const actionButton = videoContainer[i].querySelector('.video-action');
        const youtubeID = videoContainer[i].getAttribute('data-yt-id');
        const dataID = videoContainer[i].getAttribute('data-id');
        const poster = videoContainer[i].querySelector('.video-poster');

        actionButton.addEventListener("click", function() {
            const posterWidth = poster.offsetWidth;
            const posterHeight = poster.offsetHeight;
            onYouTubeIframeAPIReady(youtubeID, dataID, posterWidth, posterHeight);
            poster.remove();
            actionButton.remove();
            videoContainer[i].classList.add('generated');
        });

    }

    let player;
    function onYouTubeIframeAPIReady(videoURL, dataID, width, height) {
        let playerID = 'player-' + dataID;

        player = new YT.Player(playerID, {
        height: height,
        width: width,
        videoId: videoURL,
        playerVars: {
            'playsinline': 1
        },
        events: {
            'onReady': onPlayerReady
        }
        });
    }

    function onPlayerReady(event) {
        event.target.playVideo();
    }

}
