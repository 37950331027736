const countdownTimers = document.querySelectorAll('.section--upcoming-event .countdown');

if (typeof(countdownTimers) != 'undefined' && countdownTimers != null) {
    for (let i = 0; i < countdownTimers.length; i++) {
        let endDateTime = countdownTimers[i].dataset.datetime;
        initCountdown(endDateTime , countdownTimers[i]);
    }
}

function initCountdown(endDateTime , countdownElement) {
    let dateEnd = new Date(endDateTime).getTime();

    let countdownInterval = setInterval(function(){
        let dateNow = new Date().getTime();
        let timeleft = dateEnd - dateNow;

        let daysLeft = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        let hoursLeft = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minsLeft = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        let secsLeft = Math.floor((timeleft % (1000 * 60)) / 1000);

        let dayDisplay = countdownElement.querySelector('.unit-days');
        let hourDisplay = countdownElement.querySelector('.unit-hours');
        let minDisplay = countdownElement.querySelector('.unit-mins');
        let secDisplay = countdownElement.querySelector('.unit-secs');

        let dayDisplayTime = dayDisplay.querySelector('strong');
        let hourDisplayTime = hourDisplay.querySelector('strong');
        let minDisplayTime = minDisplay.querySelector('strong');
        let secDisplayTime = secDisplay.querySelector('strong');

        
        dayDisplayTime.textContent = daysLeft;
        hourDisplayTime.textContent = hoursLeft;
        minDisplayTime.textContent = minsLeft;
        secDisplayTime.textContent = secsLeft;


        if (timeleft < 0) {
            clearInterval(countdownInterval);
            dayDisplay.textContent = '';
            hourDisplay.remove();
            minDisplay.remove();
            secDisplay.remove();
            dayDisplay.insertAdjacentHTML('afterbegin' , '<strong>This Event Has Now Passed.</strong>');
        }
    }, 1000);
}